<template>
  <el-dialog :title="form.id ? '修改制度' : '导入制度'"
             :visible.sync="dialogVisible"
             width="620px"
             @close="dialogClose"
             @open="dialogOpen">
    <el-form v-if="dialogVisible"
             ref="form"
             v-loading="loading"
             :model="form"
             class="assest-save-form mr10"
             label-width="100px">
      <el-form-item label="制度名称"
                    prop="name"
                    :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]">
        <el-input v-model="form.name"
                  placeholder="请输入内容" />
      </el-form-item>

      <el-form-item label="文档目录"
                    prop="libraryId"
                    :rules="[ { required: true, message: '请选择文档目录', trigger: 'change' }]">
        <el-cascader v-model="form.libraryId"
                     :options="folders"
                     popper-class="cas-class"
                     style="width: 100%;"
                     :props="{ expandTrigger: 'hover', value: 'id', label: 'libraryName' }">
        </el-cascader>
      </el-form-item>

      <el-form-item label="标签"
                    prop="labelIdList">
        <el-select v-model="form.labelIdList"
                   multiple
                   placeholder="请选择">
          <el-option v-for="item in labels"
                     :key="item.id"
                     :label="item.labelName"
                     :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="制度说明">
        <el-input v-model="form.description"
                  placeholder="请输入内容"
                  type="textarea" />
      </el-form-item>
      <el-form-item label="制度文件"
                    prop="fileName"
                    :rules="[ { required: true, message: '请上传文件', trigger: 'change' }]">
        <input id="uploadFileInput"
               type="file"
               style="display: none"
               @change="uploadFileInputChange">
        <el-input v-model="form.fileName"
                  disabled
                  class="upload-file-input"
                  placeholder="请上传文件">
          <template slot="append">
            <div style="cursor: pointer;"
                 @click="uploadFileClick">
              选择文件
            </div>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button type="primary"
                 :disabled="loading"
                 @click="submitForm">
        保 存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { saveOrUpdateRegime, GetLibraryOptions, GetLabelList, GetRegimeById } from '@/api/regime/regime'

export default {
  data () {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        id: '',
        fileName: '',
        name: '',
        description: '',
        libraryId: 0,
        labelIdList: [],
      },
      linkFileName: '',
    }
  },
  props: {
    folders: {
      type: Array,
      default: []
    },
    labels: {
      type: Array,
      default: []
    },
  },
  methods: {
    uploadFileClick () {
      document.getElementById('uploadFileInput').click()
    },
    uploadFileInputChange () {
      const uploadDom = document.getElementById('uploadFileInput')
      const fileVal = uploadDom.files
      this.form.fileName = fileVal[0].name
    },
    dialogOpen () {
      this.linkFileName = this.form.fileName
    },
    dialogClose () {
      this.form = {
        id: '',
        fileName: '',
        name: '',
        description: '',
        libraryId: 0,
        labelIdList: [],
      }
      this.$refs.form.resetFields()
    },
    // eslint-disable-next-line vue/no-dupe-keys
    submitForm () {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          typeof this.form.libraryId === 'object' && (this.form.libraryId = this.form.libraryId.splice(-1)[0])
          const formData = new FormData()
          if (this.form.id) {
            formData.append('id', this.form.id)
          }
          formData.append('name', this.form.name)
          formData.append('description', this.form.description)
          formData.append('labelIds', this.form.labelIdList.join())
          formData.append('libraryId', this.form.libraryId)
          const uploadDom = document.getElementById('uploadFileInput')
          if (uploadDom.files.length > 0) {
            formData.append('file', uploadDom.files[0])
          }
          this.loading = true
          saveOrUpdateRegime(formData, this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
