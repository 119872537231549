<template>
  <div>
    <b-row class="match-height">
      <b-col style="max-width: 280px;">
        <el-card>
          <div class="filter-wrap flex-between">
            <h4>文档目录</h4>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="openClassSaveDialog(1,null)"
            >
              新增根目录
            </el-button>
          </div>
          <div :style="{maxHeight: pageH/2 - 180 + 'px',overflowY: 'scroll'}">
            <el-tree
              ref="elTree"
              :highlight-current="true"
              :data="treeData"
              node-key="id"
              :props="defaultProps"
              :expand-on-click-node="false"
              @current-change="treeNodeClick"
            >
              <span
                slot-scope="{ node, data }"
                class="field-tree-node"
              >
                <span>{{ node.label }}</span>
                <span class="eltree-operate tree-operate">
                  <el-link
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click="openClassSaveDialog(1,data)"
                  >
                    <el-tooltip
                      content="新增"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-circle-plus-outline" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click="openClassSaveDialog(2,data)"
                  >
                    <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="deleteTree(data,node)"
                  >
                    <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </span>
              </span>
            </el-tree>
          </div>
        </el-card>
        <el-card style="margin-top: 10px;">
          <div class="filter-wrap flex-between">
            <h4>标签</h4>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="openLabelDialog"
            >
              新增标签
            </el-button>
          </div>
          <div
            :style="{maxHeight: pageH/2 - 170 + 'px',overflowY: 'scroll'}"
            style="padding-top: 10px;margin-right: -10px;margin-bottom: -10px;"
          >
            <el-tag
              v-for="label in labelData"
              :key="label.id"
              :closable="delLabelPerm"
              :class="label.check && 'tag-active'"
              style="margin-right: 10px;margin-bottom:10px;cursor: pointer;"
              closable
              @click.native="labelClick(label)"
              @close="delLabel(label)"
            >
              {{ label.labelName }}
            </el-tag>
          </div>
        </el-card>
      </b-col>
      <b-col>
        <el-card class="box-card">
          <div class="filter-wrap flex-between">
            <div class="flex-start search-filter-wrap">
              <el-input
                v-model="listQuery.SearchStr"
                width="180"
                placeholder="请输入内容"
                clearable
                @clear="handleFilter"
              />
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="handleFilter"
              >
                搜索
              </el-button>
            </div>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="regimeSave(1)"
            >
              新增
            </el-button>
          </div>
          <el-table
            v-loading="loading"
            border
            fit
            highlight-current-row
            :data="tableData"
            stripe
            style="width: 100%"
          >
            <el-table-column
              prop="code"
              label="模版编号"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              prop="templateName"
              :show-overflow-tooltip="true"
              label="模版名称"
            />
            <el-table-column
              prop="directoryName"
              :show-overflow-tooltip="true"
              width="150"
              label="文档目录"
            />
            <el-table-column
              prop="labelNames"
              :show-overflow-tooltip="true"
              label="标签"
            />
            <el-table-column
              prop="status"
              width="80"
              label="状态"
            >
              <template slot-scope="{row}">
                {{ statusObj[row.status] }}
              </template>
            </el-table-column>
            <el-table-column
              prop="updateName"
              width="100"
              :show-overflow-tooltip="true"
              label="编辑人"
            />

            <el-table-column
              prop="updateTime"
              label="更新时间"
              width="100"
            >
              <template slot-scope="{row}">
                {{ formatDateStr(row.updateTime) }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="165px"
            >
              <template slot-scope="{row}">
                <el-link
                  class="mr10"
                  :underline="false"
                  type="primary"
                  @click="regimeSave(2, row.code)"
                >
                  <el-tooltip
                    content="复制"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-document-copy" />
                  </el-tooltip>
                </el-link>
                <el-link
                  class="mr10"
                  :underline="false"
                  type="primary"
                  @click="regimeSave(3,row.code)"
                >
                  <el-tooltip
                    content="修改"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-edit" />
                  </el-tooltip>
                </el-link>
                <el-link
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="preview(row)"
                >
                  <el-tooltip
                    content="预览"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-document" />
                  </el-tooltip>
                </el-link>
                <el-link
                  :underline="false"
                  type="primary"
                  @click="delAssest(row.code)"
                >
                  <el-tooltip
                    content="删除"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-delete" />
                  </el-tooltip>
                </el-link>
              </template>
            </el-table-column>
          </el-table>
          <pagination
            v-show="total>0"
            :total="total"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.pageSize"
            @pagination="getList"
          />
        </el-card>
      </b-col>
    </b-row>
    <CatalogueDialog
      ref="catalogueDialog"
      @addTreeNode="addTreeNode"
      @updateTreeNode="updateTreeNode"
    />
    <LabelDialog
      ref="labelDialog"
      @saveLabel="saveLabel"
    />
    <RegimeDialog
      ref="regimeDialog"
      :labels="labelData"
      :folders="treeData"
      @handleFilter="handleFilter"
    />
  </div>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import CatalogueDialog from '@/views/template/regime/CatalogueDialog.vue'
import LabelDialog from '@/views/template/regime/LabelDialog.vue'
import RegimeDialog from '@/views/template/regime/RegimeDialog.vue'
import {
  GetRegimeListByPage, DownloadFile, GetLabelList, GetAllLibrary, DeleteLabelByIds, DeleteLibraryById,
} from '@/api/regime/regime'
import { DeleteTemplateById } from '@/api/knowledgeBase/template'
import {error, formatDateStr, success} from '@core/utils/utils'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    pagination,
    CatalogueDialog,
    LabelDialog,
    RegimeDialog,
    VuePerfectScrollbar,
  },
  data() {
    const listQuery = {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      enterpriseId: 100,
      labelIds: [],
      directoryId: 0,
    }
    return {
      statusObj: {
        1: '编辑中',
        2: '编辑完成',
      },
      defaultProps: {
        children: 'children',
        label: 'directoryName',
      },
      childPage: [],
      accessToken: localStorage.getItem('accessToken'),
      saveParentDir: false, // 新增一级目录
      saveChildDir: false, // 新增子目录
      updateDir: false, // 修改目录
      delDir: false, // 删除目录
      saveLabelPerm: false, // 新增标签
      delLabelPerm: false, // 删除标签
      saveRegime: false, // 新增制度
      updateRegime: false, // 修改制度
      delRegime: false, // 删除制度
      downloadRegime: false, // 下载制度
      readRegime: false, // 下载制度
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
        enterpriseId: 100,
        labelIds: [],
        directoryId: 0,
      },
      initListQuery: { ...listQuery },
      tableData: [],
      treeData: [],
      optClassData: {},
      labelData: [],
    }
  },
  created() {

  },
  mounted() {
    this.getList()
    this.getAllTree()
    this.getAllLabel()
  },
  methods: {
    formatDateStr,
    labelClick(item) {
      if (this.listQuery.labelIds.includes(item.id)) {
        this.listQuery.labelIds = this.listQuery.labelIds.filter(id => id !== item.id)
      } else {
        this.listQuery.labelIds.push(item.id)
      }
      for (const label of this.labelData) {
        if (label.id === item.id) {
          this.$set(label, 'check', !label.check)
          break
        }
      }
      this.handleFilter()
    },
    preview(row) {
      const routeUrl = this.$router.resolve({
        path: '/template-preview', query: { id: row.code },
      })
      window.open(routeUrl.href, '_blank')
    },
    downloadFile(id) {
      DownloadFile(id)
    },
    delAssest(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        const codes = []
        codes.push(id)
        DeleteTemplateById({ codes })
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.handleFilter()
            } else {
              error(resData.msg)
            }
          })
      }).catch(() => { })
    },
    regimeSave(type, id) {
      this.$router.push({
        path: '/regime-save',
        query: {
          id,
          t: type,
        },
      })
    },
    getList() {
      this.loading = true
      GetRegimeListByPage(this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    saveLabel() {
      this.getAllLabel()
    },
    delLabel(label) {
      this.$confirm(`是否确认删除该标签（${label.labelName}）?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteLabelByIds({ ids: [label.id] })
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              this.getAllLabel()
              success(resData.msg)
            } else {
              error(resData.msg)
            }
          })
      }).catch(() => { })
    },
    addTreeNode(data) {
      if (data.parentId === 0) {
        this.treeData.push(data)
      } else {
        if (!this.optClassData.children) {
          this.$set(this.optClassData, 'children', [])
        }
        this.optClassData.children.push(data)
      }
    },
    updateTreeNode(data) {
      this.optClassData.label = data.directoryName
      this.optClassData.directoryName = data.directoryName
    },
    getAllLabel() {
      GetLabelList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.labelData = resData.data
          for (const item of this.labelData) {
            item.value = item.id
            item.label = item.labelName
          }
        }
      })
    },
    getAllTree() {
      GetAllLibrary().then(res => {
        const resData = res.data
        this.treeData = resData.data
      })
    },
    listToTree(list) {
      // hash
      const map = {}
      for (const item of list) {
        item.label = item.directoryName
        map[item.id] = item
      }
      for (const item of list) {
        if (!(item.parentId > 0)) {
          continue
        }
        const parent = map[item.parentId]
        if (parent) {
          if (parent.children === undefined) {
            parent.children = []
          }
          parent.children.push(item)
        }
      }
      return list.filter(i => i.parentId === 0)
    },
    treeNodeClick(data) {
      if (this.listQuery.directoryId === data.id) {
        this.listQuery.directoryId = 0
        this.$refs.elTree.setCurrentKey(null)
      } else {
        this.listQuery.directoryId = data.id
      }
      this.getList()
    },
    openLabelDialog() {
      this.$refs.labelDialog.dialogVisible = true
    },
    openClassSaveDialog(type, data) {
      this.$refs.catalogueDialog.dialogVisible = true
      if (type === 2) {
        this.$refs.catalogueDialog.form.id = data.id
        this.$refs.catalogueDialog.form.parentId = data.parentId
        this.$refs.catalogueDialog.form.directoryName = data.directoryName
      } else if (data) {
        this.$refs.catalogueDialog.form.parentId = data.id
        this.$refs.catalogueDialog.form.parentName = data.directoryName
      } else {
        this.$refs.catalogueDialog.form.parentId = 0
      }
      this.optClassData = data
    },
    deleteTree(data, node) {
      this.$confirm('是否确认删除目录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteLibraryById({ id: data.id }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            const { parent } = node
            const children = parent.data.children || parent.data
            const index = children.findIndex(d => d.id === data.id)
            children.splice(index, 1)
            success(res.data.msg)
          } else {
            error(res.data.msg)
          }
        })
      }).catch(() => { })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-item-wrap {
  max-height: 300px;
}

.field-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding-right: 8px;
}
.tree-operate {
  position: absolute;
  right: 0px;
}
.tag-active {
  background-color: #7367f0 !important;
  color: #fff !important;
  border: none !important;
  ::v-deep .el-tag__close {
    color: #fff !important;
  }
}
.label {
  display: inline-block;
  background-color: rgba(115, 103, 240, 0.12);
  border: 1px solid rgba(115, 103, 240, 0.12);
  margin: 5px;
  padding: 0 8px;
  border-radius: 3px;
  line-height: 20px;
  color: #7367f0;
}
</style>
